import React, { useState } from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ReplicaAndLeadLink = ({
  payoutTab,
  currency,
  replicaLink,
  leadCaptureLink,
  conversionFactor,
  productLinks,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("replica");
  const [copied, setCopied] = useState(false);
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const copyToClipboard = async (text) => {
    if (navigator && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
        // Reset copied status after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (error) {
        console.error("Clipboard copy failed:", error);
      }
    } else {
      // Clipboard API is not supported, provide a fallback method
      try {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        setCopied(true);
        // Reset copied status after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (error) {
        console.error("Fallback clipboard copy failed:", error);
      }
    }
  };

  const renderPopover = (content) => (
    
    <Popover>
      <Popover.Body>{`${content}`}</Popover.Body>
    </Popover>
  );

  const renderSocialIcons = (links) => {
    return links?.map((icon, index) => (
      <React.Fragment key={index}>
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={renderPopover(copied ? "Copied" : icon.name)}
        >
          {icon?.name === "Lead Capture Link" || icon?.name === "Replica Link" ? (
            <div
              className="replica_link_sec_ico"
              onClick={() => copyToClipboard(icon.link)}
            >
              <img src={`images/${icon.icon}`} alt={icon.name} />
            </div>
          ) : (
            <a
              className="replica_link_sec_ico"
              target="_blank"
              href={icon.link}
            >
              <img src={`images/${icon.icon}`} alt={icon.name} />
            </a>
          )}
        </OverlayTrigger>
      </React.Fragment>
    ));
  };
  const renderCopy = (product) => (
    <React.Fragment>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={renderPopover(copied ? "Copied" : "Product Link")}
      >

           <button  onClick={() =>
                    copyToClipboard(product.name)
                  } >Copy</button> 
        {/* <div className="replica_link_sec_ico"  onClick={() => copyToClipboard(product.name)} >
          <img src={`images/copy.svg`} alt={'Product Link'} />
        </div> */}
      </OverlayTrigger>
    </React.Fragment>
  );

  const renderPayoutRows = () => {
    return (
      <>
        <div className="dashboard_payout_right_2_cnt_row" key="1">
          {productLinks?.map((product, index) => {
            return (
              <>

              <div class="content" key={index}>
                <i className="fa fa-link" aria-hidden="true"></i>
                <input
                  type="text"
                  readonly
                  value={product.name}
                />
                <strong>
                  
                    {renderCopy(product)}
                 
                </strong>
                </div>
               
                
              </>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className="replica_lead_btn_top">
        {/* {!!moduleStatus?.replicated_site_status && (
          <Link
            className={`replica_lead_tab_btn ${
              activeTab === "replica" ? "active" : ""
            }`}
            onClick={() => handleTabClick("replica")}
          >
            {t("replica")}
          </Link>
        )} */}
        {!!moduleStatus?.lead_capture_status && (
          <Link
            className={`replica_lead_tab_btn ${
              activeTab === "leadcapture" ? "active" : ""
            }`}
            onClick={() => handleTabClick("leadcapture")}
          >
            {t("leadCapture")}
          </Link>
        )}
      </div>
      {!!moduleStatus?.replicated_site_status && activeTab === "replica" && (
        <div className="replica_link_sec">
          <div className="replica_link_sec_row">
            {renderSocialIcons(replicaLink)}
          </div>
        </div>
      )}
      {!!moduleStatus?.lead_capture_status && activeTab === "leadcapture" && (
        <div className="replica_link_sec">
          <div className="replica_link_sec_row">
            {renderSocialIcons(leadCaptureLink)}
          </div>
        </div>
      )}

      <div className="dashboard_payout_right_section_2">
        <div className="dashboard_payout_right_section_2_head">
          {t("productLinks")}
        
        </div>
        <div className="dashboard_payout_right_2_cnt">{renderPayoutRows()}</div>
      </div>
    </div>
  );
};

export default ReplicaAndLeadLink;
