//const BASE_URL = "http://192.168.7.37:5001/node_api";
const BASE_URL = "https://admin.vinnit-x.com/node_api";

// const BASE_URL = "http://162.19.146.132:1308/node_api";
// const BASE_URL = "https://api.infinitemlmsoftware.com/";
const DEFAULT_KEY = "387245a5a919ea2071cc406b10b89d4685e5cc8e";
const PAYPAL_CLIENT_ID =
  "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75";

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID };
