import React  ,{useState} from "react";
import { Modal, Button } from "react-bootstrap";
import { OverlayTrigger, Popover } from "react-bootstrap";

function LinkModal({ show, onHide, links, paramsList }) {

  console.log("paramsList",paramsList)
    const [copied, setCopied] = useState(false);
     const renderPopover = (content) => (
    <Popover>
      <Popover.Body>{`${content}`}</Popover.Body>
    </Popover>
  );
   const copyToClipboard = async (text) => {
    if (navigator && navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
        // Reset copied status after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (error) {
        console.error("Clipboard copy failed:", error);
      }
    } else {
      // Clipboard API is not supported, provide a fallback method
      try {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        setCopied(true);
        // Reset copied status after a delay (e.g., 2 seconds)
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (error) {
        console.error("Fallback clipboard copy failed:", error);
      }
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      size="lg"
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="text-center">Registration Links</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="popup_cnt_sec">
          {links.map((linksData, index) => {

            let linkUrl = `${linksData.site_url}/sponsor/${linksData.sponsor}/pos/${paramsList.position}/fname/${paramsList.placement}/product/${linksData.slug}`;
            return (
              <div class="content" key={index}>
                <i className="fa fa-link" aria-hidden="true"></i>
                <input
                  type="text"
                  readonly
                  value={linkUrl}
                />

                    <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="top"
                overlay={renderPopover(copied ? "Copied" : linkUrl)}
              >

                 <button  onClick={() =>
                    copyToClipboard(linkUrl)
                  } >Copy</button> 
           
              </OverlayTrigger>
                
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button
          className="btn-primary"
          variant="secondary"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LinkModal;
