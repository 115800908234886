import React from "react";

const FooterSection = () => {
  return (
    <footer className="footer_copy">
      <div className="container">
        <p>© 2024 VINNIT-X, alle Rechte vorbehalten</p>
      </div>
    </footer>
  );
};

export default FooterSection;
